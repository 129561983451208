/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable import/extensions */
import _ from 'lodash';
import NbisInputDropDown from 'presentation/view/components/NbisInputDropDown';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DateTimePicker, DropdownOptions, GroupCheckboxList, GroupRadioChangeProps, HPHGroupCheckbox, HPHGroupRadioButton, HPHInputMask, HPHInputTextarea, HPHToggle, InputField, SliderRange, TextType } from 'veronica-ui-component/dist/component/core';
import { StyledParentLabel, StyledText } from 'veronica-ui-component/dist/component/core/styled/uiFramework.styled';
import { CommonFieldWrapper } from './styled/CommonFieldWrapper.styled';

function paddedDateTime(dateTime: Date) {
  const year = dateTime.getFullYear().toString().padStart(4, '0');
  const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
  const date = dateTime.getDate().toString().padStart(2, '0');
  const hour = dateTime.getHours().toString().padStart(2, '0');
  const minute = dateTime.getMinutes().toString().padStart(2, '0');
  const second = dateTime.getSeconds().toString().padStart(2, '0');
  return { year, month, date, hour, minute, second };
}

export const getDateInDbFormat = (dateTime: Date): string => {
  const { year, month, date, hour, minute, second } = paddedDateTime(dateTime);
  return `${year}-${month}-${date}T${hour}:${minute}:${second}.000+08:00`;
};

export enum FieldType {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  TEXT_MASK = 'text_mask',
  RADIO_GROUP = 'radio_group',
  CHECKBOX_GROUP = 'checkbox_group',
  DROPDOWN = 'dropdown',
  TOGGLE = 'toggle',
  DATE_TIME = 'date_time',
  RANGE = 'range',
}

export interface IWarningMessage {
  isShow: boolean;
  message: string;
}

export interface IFieldValueCoVoyValue {
  com: string;
  vsl: string;
  voy?: string;
}

export const defaultFieldValueCoVoyValue: IFieldValueCoVoyValue = {
  com: '',
  vsl: '',
  voy: '',
};

export type IFieldValue = string | number | boolean | IFieldValueCoVoyValue | string[] | null | '';

export interface ICommonField {
  isReadOnly: boolean; // Make field read only mode
  isReadOnlyInEditMode?: boolean; // Make field edit in read in Edit mode
  readOnlyValue?: string; // Value for read only mode
  isSaveClicked?: boolean; // If user have been clicked on Save button
  fieldType?: FieldType;
  fieldKey: string; // Field key
  fieldLabel?: string; // Field lable
  fieldValue?: IFieldValue; // Field valie
  requiredFieldList: string[]; // List of required fields
  readOnly?: boolean; // Make field disabled
  isShowFieldInReadOnlyMode?: boolean;

  placeholder?: string | undefined; // Field placeholder
  type?: TextType | undefined; // value type
  options?: any[] | undefined
  secondKey?: string;
  secondValue?: any;
  keyPairs?: { [key: string]: string };
  isMultipleDropDown?: boolean;
  valueType?: TextType;
  maxLength?: number;
  makeDisabled?: boolean;
  isShowOptional?: boolean;
  errorMessages?: { [x: string]: string; };
  warningMessage?: IWarningMessage;
  helpTextElement?: string | JSX.Element;
  allValues?: string;
  isTextFieldCapitalize?: boolean;
  isShowMissingError?: boolean;
  isShowCrossIcon?: boolean;
  pRef?: any;
  onFieldChange: (fieldName: string, fieldValue: IFieldValue, fFullValue?: any) => void;
  onSecondFieldChange?: (fieldName: string, fieldValue: any) => void;
  onMaskEnter?: (fieldName: string, fieldValue: IFieldValue, fFullValue?: any) => void;
  onCrossClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void,
  dropdownVirtualScroll?: boolean,
  slotChar?: string,
  [x: string]: any;
}

export const NbisCommonField: React.FC<ICommonField> = (props: ICommonField) => {
  const control = useForm();
  const { fieldValue, secondValue, allValues, isReadOnly, readOnlyValue, placeholder, fieldType, isShowOptional, isSaveClicked, requiredFieldList, fieldLabel, options, secondKey, keyPairs, isMultipleDropDown, warningMessage, helpTextElement, isReadOnlyInEditMode, readOnly, valueType, maxLength, isShowFieldInReadOnlyMode, makeDisabled, errorMessages, isTextFieldCapitalize, isShowMissingError, onFieldChange, dropdownVirtualScroll = false, slotChar = '/', ...rest } = props;

  const [showAllValues, setShowAllValues] = useState<boolean>(false);

  const fieldKey: string = props.fieldKey.indexOf('.') >= 0 ? props.fieldKey.split('.')[1] : props.fieldKey;
  const [legendPosition, setLegendPosition] = useState({ left: 0, top: 0 });
  const uniqueIdRef = useRef<string>(`common-field-readonly-div-${crypto.randomUUID()}`);

  const calculatePosition = useCallback(() => {
    let pDiv;
    if ((isReadOnly || !isReadOnlyInEditMode) && !isShowFieldInReadOnlyMode) {
      pDiv = document.querySelector(`#${uniqueIdRef.current}`);
    }
    if (pDiv) {
      setLegendPosition({
        left: (pDiv as HTMLElement).offsetLeft + ((pDiv as HTMLElement).offsetWidth / 4), // 水平居中
        top: (pDiv as HTMLElement).offsetTop + (pDiv as HTMLElement).offsetHeight + 25
      });
    }
  }, [isReadOnly, isReadOnlyInEditMode, isShowFieldInReadOnlyMode]);

  const handleDropDownMouseLeave = () => {
    setShowAllValues(false);
    calculatePosition();
  };

  const handleDropDownMouseEnter = () => {
    setShowAllValues(true);
    calculatePosition();
  };

  const commonFieldLegend = (
    <div
      id='charge-detail-legend'
      className={`detail-legend ${showAllValues ? 'added' : ''}`}
      style={{ left: legendPosition.left, top: legendPosition.top }}
    >
      <ul>
        <li><p className='tooltip'><span>{allValues}</span></p></li>
      </ul>
    </div>
  );

  useEffect(() => {
    calculatePosition();

    window.addEventListener('resize', calculatePosition);

    return () => {
      window.removeEventListener('resize', calculatePosition);
    };
  }, [calculatePosition, isReadOnly, isReadOnlyInEditMode, isShowFieldInReadOnlyMode]);

  // const highlightItems = useCallback((options: any[], fieldValue: IFieldValue) => {
  //   if (!options || _.isEmpty(options)) return;

  //   if (!fieldValue) {
  //     return options;
  //   }

  //   const valueSet = fieldValue
  //     ? (Array.isArray(fieldValue)
  //       ? new Set(fieldValue)
  //       : new Set([fieldValue]))
  //     : new Set();

  //   const optionsMap = options.reduce((acc, option) => {
  //     acc[option.value] = { ...option, showText: `` };
  //     return acc;
  //   }, {} as { [key: string]: any });

  //   valueSet?.forEach((item: any) => {
  //     if (optionsMap[item]) {
  //       optionsMap[item].showText = `<span class="highlight">${optionsMap[item].dropdownLabel ?? optionsMap[item].value}</span>`;
  //     }
  //   });

  //   const newOptions = Object.values(optionsMap);

  //   return (newOptions as DropdownOptions[]);
  // }, []);

  if ((isReadOnly || !isReadOnlyInEditMode) && !isShowFieldInReadOnlyMode) {
    return (
      <>
        <div id={`${uniqueIdRef.current}`} onMouseEnter={handleDropDownMouseEnter} onMouseLeave={handleDropDownMouseLeave}>
          <StyledParentLabel>{fieldLabel}</StyledParentLabel>
          <StyledText style={{ fontWeight: '400', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>
            {readOnlyValue}
          </StyledText>
        </div>
        {showAllValues && !_.isEmpty(allValues) && commonFieldLegend}
      </>
    );
  }

  /* istanbul ignore next */
  function getError(fKey: string | undefined = undefined, fValue: string | undefined = undefined) {
    const key = fKey ? fKey : props.fieldKey;
    const value = fValue ? fValue : fieldValue;
    if (!errorMessages) return '';

    if (!isShowMissingError && value !== '') return errorMessages[key] ? errorMessages[key] : '';
    else return errorMessages[key] ? 'Missing.' : '';
  }

  const style: { [x: string]: string } = {};
  if (readOnly) {
    style.borderWidth = '0px';
  }

  // Input field chnage handler
  /* istanbul ignore next */
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onFieldChange(fieldKey, e.target.value, null);
  };

  // Textarea field change handler
  /* istanbul ignore next */
  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onFieldChange(fieldKey, e.target.value, null);
  };

  // Radio Button change handler
  /* istanbul ignore next */
  const handleRadioButtonboxChange = (e: GroupRadioChangeProps) => {
    onFieldChange(fieldKey, e.key, null);
  };

  // Checkbox field change handler
  /* istanbul ignore next */
  const handleCheckboxChange = (e: any) => {
    let fValue: any;
    if (isMultipleDropDown) fValue = _.uniq(e?.map((item: GroupCheckboxList) => item.key && item));
    else {
      fValue = e && (Array.isArray(e) ? (e.length > 0 ? e[0].key : null) : e.key);
    }

    onFieldChange(fieldKey, fValue, null);
  };

  // Input mask field change handler
  /* istanbul ignore next */
  const handleInputMaskChange = (e: any) => {
    let fKey: string = fieldKey;
    let fValue: any = '';

    const keyFound: string = (function (obj1, obj2) {
      let kFound: string = '';
      if (obj1 && !Array.isArray(obj1) && typeof obj1 === 'object') {
        const keys: string[] = Object.keys(obj1);
        keys?.forEach((k: string) => {
          if (obj1[k as keyof typeof obj1] !== obj2[k]) {
            kFound = k;
          }
        });
      }
      return kFound || '';
    })(fieldValue, e);
    if (keyFound !== '') {
      fKey = keyPairs ? keyPairs[keyFound] : '';
      fValue = e[keyFound];
    }

    onFieldChange(fKey, fValue, null);
  };

  // Input mask field enter handler
  /* istanbul ignore next */
  const handleInputMaskEnterChange = (e: any) => {
    if (!props.onMaskEnter) return;

    let fKey: string = fieldKey;
    let fValue: any = '';

    const keyFound: string = (function (obj1, obj2) {
      let kFound: string = '';
      if (obj1 && !Array.isArray(obj1) && typeof obj1 === 'object') {
        const keys: string[] = Object.keys(obj1);
        keys?.forEach((k: string) => {
          if (obj1[k as keyof typeof obj1] !== obj2[k]) {
            kFound = k;
          }
        });
      }
      return kFound || '';
    })(fieldValue, e);
    if (keyFound !== '') {
      fKey = keyPairs ? keyPairs[keyFound] : '';
      fValue = e[keyFound];
    }

    props.onMaskEnter(fKey, fValue, null);
  };

  // Dropdown change handler
  /* istanbul ignore next */
  const handleDropDownChange = (e: DropdownOptions | DropdownOptions[] | null | any) => {
    let fValue: any;

    if (e === null) {
      fValue = '';
    } else if (!(e instanceof Array)) {
      fValue = e.value || e;
    } else if (e && e.length > 0) {
      fValue = _.uniq(e?.map((item: DropdownOptions) => item.value && item));
    }

    onFieldChange(fieldKey, fValue, e?.subOptions);
  };

  // Toggle field change handler
  /* istanbul ignore next */
  const handleToggleChange = (e: any) => {
    onFieldChange(fieldKey, e.value, null);
  };

  // Date field change handler
  /* istanbul ignore next */
  const handleDateChange = (e: Date | Date[] | undefined) => {
    let fValue: any = null;
    if (e !== undefined && Array.isArray(e) && e.length > 0) {
      fValue = e?.map((dt) => getDateInDbFormat(dt));
    } else if (e !== undefined && !Array.isArray(e)) {
      fValue = e && getDateInDbFormat(e);
    }

    onFieldChange(fieldKey, fValue, null);
  };

  // Slider range field change handler
  /* istanbul ignore next */
  const handleSliderRangeChange = (e: string | number, key: string) => {
    let fValue: any = e;
    let fFull: any = null;

    if (key === fieldKey) {
      props.onFieldChange(key, fValue, fFull);
    } else if (key === secondKey) {
      props.onSecondFieldChange && props.onSecondFieldChange(key, fValue);
    }
  };

  const commonProps: { [x: string]: any } = {
    label: fieldLabel,
    optional: (() => {
      switch (fieldType) {
        case FieldType.TEXT:
          return isShowOptional && (!readOnly && !requiredFieldList.includes(fieldKey));
        default:
          return isShowOptional && (!requiredFieldList.includes(fieldKey));
      }
    })(),
    errorMessage: (() => {
      switch (fieldType) {
        case FieldType.TEXT_MASK:
          let error: string = '';
          if (keyPairs && !Array.isArray(fieldValue) && typeof fieldValue === 'object') {
            if (getError(keyPairs.com, fieldValue?.com) !== '')
              error = getError(keyPairs.com, fieldValue?.com);
            else if (getError(keyPairs.vsl, fieldValue?.vsl) !== '')
              error = getError(keyPairs.vsl, fieldValue?.vsl);
            else if (getError(keyPairs.voy, fieldValue?.voy) !== '')
              error = getError(keyPairs.voy, fieldValue?.voy);
          }
          return (isSaveClicked ? error : '');
        case FieldType.RANGE:
          const sKey = secondKey ? secondKey : '';
          return (isSaveClicked ? getError() : '') || (isSaveClicked ? getError(sKey, secondValue) : '');
        default:
          return (isSaveClicked ? getError() : '');
      }
    })(),
    disabled: (() => {
      switch (fieldType) {
        case FieldType.CHECKBOX_GROUP:
        case FieldType.TOGGLE:
          return isReadOnly && isShowFieldInReadOnlyMode;
        default:
          return makeDisabled;
      }
    })(),
    ref: props.pRef,
    crossIcon: Boolean(props.isShowCrossIcon),
    onCrossClick: props.onCrossClick,
    ...rest,
  };

  /* istanbul ignore next */
  const getField = (): JSX.Element => {
    switch (fieldType) {
      case FieldType.TEXT:
        return (
          <InputField
            {...commonProps}
            helpIcon={helpTextElement ? true : false}
            toolTipText={(helpTextElement && typeof helpTextElement === 'string') ? helpTextElement : ''}
            style={style}
            placeholder={placeholder}
            type={valueType}
            value={typeof fieldValue === 'string' ? fieldValue : ''}
            name={fieldKey}
            maxLength={maxLength}
            width={'100%'}
            onChange={handleInputChange} />
        );
      case FieldType.TEXTAREA:
        return (
          <HPHInputTextarea
            {...commonProps}
            helpIcon={helpTextElement ? true : false}
            toolTipText={(helpTextElement && typeof helpTextElement === 'string') ? helpTextElement : ''}
            className={'textarea-input'}
            width={'100%'}
            resizable={true}
            placeholder={placeholder}
            type="text"
            value={fieldValue as string}
            name={fieldKey}
            onChange={handleTextAreaChange} />
        );
      case FieldType.RADIO_GROUP:
        return (
          <HPHGroupRadioButton
            {...commonProps}
            helpIcon={helpTextElement ? true : false}
            toolTipText={(helpTextElement && typeof helpTextElement === 'string') ? helpTextElement : ''}
            radioOptions={options}
            orientation="horizontal"
            checked={options?.find(item => item.key === fieldValue)?.name}
            name={fieldKey}
            onChange={handleRadioButtonboxChange} />
        );
      case FieldType.CHECKBOX_GROUP:
        return (
          <HPHGroupCheckbox
            {...commonProps}
            helpIcon={helpTextElement ? true : false}
            toolTipText={(helpTextElement && typeof helpTextElement === 'string') ? helpTextElement : ''}
            checkboxData={options}
            selectedValues={Array.isArray(fieldValue) ? fieldValue : [fieldValue]}
            onChange={handleCheckboxChange} />
        );
      case FieldType.TEXT_MASK:
        return (
          <HPHInputMask
            {...commonProps}
            helpIcon={helpTextElement ? true : false}
            toolTipText={(helpTextElement && typeof helpTextElement === 'string') ? helpTextElement : ''}
            control={control}
            required={false}
            systemIndicator={false}
            placeholder={''}
            type={valueType}
            slotChar={slotChar}
            value={(fieldValue && !Array.isArray(fieldValue) && typeof fieldValue === 'object') ? fieldValue : defaultFieldValueCoVoyValue}
            onDataChange={handleInputMaskChange}
            onEnter={handleInputMaskEnterChange} />
        );
      case FieldType.DROPDOWN:
        return (
          <NbisInputDropDown
            {...commonProps}
            helpIcon={helpTextElement ? true : false}
            toolTipText={(helpTextElement && typeof helpTextElement === 'string') ? helpTextElement : ''}
            field={'value'}
            width={'100%'}
            inputType="freeText"
            isVirtualScroll={dropdownVirtualScroll}
            value={isMultipleDropDown
              ? (Array.isArray(fieldValue) && fieldValue.length
                ? fieldValue?.map((item: any) => ({
                  value: item.toString(),
                }))
                : [])
              : fieldValue !== null && fieldValue !== undefined
                ? fieldValue.toString()
                : null}
            name={fieldKey}
            options={options}
            mode={isMultipleDropDown ? 'multiple' : 'single'}
            onChange={handleDropDownChange}
          />
        );
      case FieldType.TOGGLE:
        return (
          <HPHToggle
            {...commonProps}
            toolTipPlacement={'top'}
            tooltipDisabled={true}
            helpIcon={helpTextElement ? true : false}
            toolTipText={(helpTextElement && typeof helpTextElement === 'string') ? helpTextElement : ''}
            // toolTipText={fieldLabel}
            name={fieldKey}
            control={control}
            checked={fieldValue ? Boolean(fieldValue) : false}
            onChange={handleToggleChange} />
        );
      case FieldType.DATE_TIME:
        return (
          <DateTimePicker
            {...commonProps}
            helpIcon={helpTextElement ? true : false}
            toolTipText={(helpTextElement && typeof helpTextElement === 'string') ? helpTextElement : ''}
            value={(typeof fieldValue === 'string' && fieldValue) ? new Date(fieldValue) : ''}
            name={fieldKey}
            placeholder='dd/mm/yyyy hh:mm'
            dateFormat='dd/mm/yy'
            timeFormat='hh:mm'
            onChange={handleDateChange} />
        );
      case FieldType.RANGE:
        return (
          <div className={'input-range'}>
            <SliderRange
              {...commonProps}
              helpIcon={helpTextElement ? true : false}
              toolTipText={(helpTextElement && typeof helpTextElement === 'string') ? helpTextElement : ''}
              type='text'
              maxLength={maxLength}
              valuetxt={`${fieldValue},${secondValue}`}
              isNumberRange={valueType === 'text' ? '0' : '1'}
              onSliderStartInputChange={(e: any) => handleSliderRangeChange(e, fieldKey)}
              onSliderEndInputChange={(e: any) => handleSliderRangeChange(e, (secondKey ? secondKey : ''))} />
          </div>
        );
      default:
        return <></>;
    }
  };

  let mainStyle: { [x: string]: string } = {};
  if (warningMessage?.isShow) {
    mainStyle = {
      position: 'relative',
    };
  }

  // if (helpTextElement && fieldType !== FieldType.CHECKBOX_GROUP) {
  // mainStyle = {
  //   ...mainStyle,
  //   display: 'flex',
  //   flexDirection: 'row',
  // };
  // }

  return (
    <CommonFieldWrapper className={`common-field ${isTextFieldCapitalize && 'capitalize'} ${fieldKey.toLowerCase()}`} style={mainStyle}>
      {getField()}
      {/* {helpTextElement && fieldType !== FieldType.CHECKBOX_GROUP && <div className={'help_text'}>{helpTextElement}</div>} */}
      {warningMessage?.isShow && <span style={{ 'color': 'orange', 'fontSize': '11px', 'position': 'absolute', 'bottom': '-14px' }}>{warningMessage.message}</span>}
      {/* {showAllValues && !_.isEmpty(allValues) && commonFieldLegend} */}
    </CommonFieldWrapper>
  );
};

NbisCommonField.defaultProps = {
  fieldLabel: '',
  fieldValue: '',
  readOnlyValue: '',
  isSaveClicked: false,
  isShowOptional: true,
  fieldType: FieldType.TEXT,
  isReadOnlyInEditMode: true,
  isShowFieldInReadOnlyMode: false,
  options: [],
  readOnly: false,
  isMultipleDropDown: false,
  valueType: 'text',
  maxLength: 5,
  makeDisabled: false,
  errorMessages: {},
  warningMessage: { isShow: false, message: '' },
  helpTextElement: undefined,
  isTextFieldCapitalize: false,
  isShowMissingError: true,
  requiredFieldList: [],
  isShowCrossIcon: false,
};