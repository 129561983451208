/* eslint-disable import/extensions */
import React, { useCallback, useEffect, useState } from 'react';
import { HPHCheckbox } from 'veronica-ui-component/dist/component/core/CheckBox/CheckBox';
import { IconButton } from 'veronica-ui-component/dist/component/core/IconButtons/IconButtons';
import { NbisFilterListWrapper } from './styled/NbisFilterList.styled';

interface IRowFilterList {
    columnKey: string;
    isChecked: boolean;
    isDraggable: boolean;
    children: JSX.Element;
    onCheckboxChange: (e: any, columnKey: string) => void;
    onMoveUp: (e: any) => void;
}

export interface IColumnConfiguration {
    columnKey: string;
    columnName: string;
    visible: boolean;
    isShow?: boolean;
    order?: number;
    isDraggable?: boolean;
}

export interface IFilterList {
    colDef: IColumnConfiguration[];
    onMoveToIndex?: (columnKey: string, toIndex: number) => void;
    onVisibilityToggle: (columnKeys: string[], isVisible: boolean) => void;
}

const RowFilterList: React.FC<IRowFilterList> = (props: IRowFilterList) => {
    const { columnKey, isChecked, children, onCheckboxChange, onMoveUp, isDraggable } = props;
    const [visible, setVisible] = useState<boolean>(isChecked);

    useEffect(() => {
        setVisible(isChecked);
    }, [isChecked]);

    const handleChange = useCallback((e: any) => {
        onCheckboxChange(e, columnKey);
        setVisible(e.target.checked);
    }, [columnKey, onCheckboxChange]);

    return <>
        <HPHCheckbox label="" checked={visible} onChange={handleChange} />
        <IconButton fileName="Icon-drag" size="medium" tooltipDisable={true} toolTipArrow={false} onClick={() => { }} disabled={!isDraggable} />
        <button type="button" disabled={!isDraggable}>{children}</button>
        <IconButton fileName="Icon-angle-upTop" size="small" tooltipDisable={true} toolTipArrow={false} onClick={(e: any) => onMoveUp(e)} />
    </>;
};

export const NbisFilterList: React.FC<IFilterList> = (props: IFilterList) => {
    const { colDef, onMoveToIndex, onVisibilityToggle } = props;
    const [colConfiguration, setColConfiguration] = useState<IColumnConfiguration[]>(colDef);
    const [allSelect, setAllSelect] = useState<boolean>(true);
    const [searchText, setSearchText] = useState<string>('');

    useEffect(() => {
        const updatedCol = colDef?.map(i => (i.columnKey !== 'action' && i.columnKey !== 'selectionCol' && i.columnKey !== 'dragCol' && i.columnKey !== '"frontaction"') ? ({ ...i, isShow: i.isShow || true }) : { ...i });
        setColConfiguration(updatedCol);
    }, [colDef]);

    // Set all checkbox
    const handleAllCheckbox = useCallback((e: any) => {
        const tmpColConfiguration: IColumnConfiguration[] = colConfiguration?.map(i => ({ ...i, visible: e.target.checked }));
        setColConfiguration([...tmpColConfiguration]);
        setAllSelect(e.target.checked);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onVisibilityToggle && onVisibilityToggle(tmpColConfiguration?.map(cDef => cDef.columnKey), e.target.checked);
    }, [colConfiguration, onVisibilityToggle]);

    // Set all checkbox
    const handleCheckbox = useCallback((e: any, columnKey: string) => {
        const tmpColConfiguration: IColumnConfiguration[] = colConfiguration?.map(i => ({ ...i, visible: (i.columnKey === columnKey ? e.target.checked : i.visible) }));
        setColConfiguration([...tmpColConfiguration]);
        setAllSelect(!tmpColConfiguration?.some(i => !i?.visible));
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onVisibilityToggle && onVisibilityToggle([columnKey], e.target.checked);
    }, [colConfiguration, onVisibilityToggle]);

    // Single checkbox
    const handleChange = useCallback((e: any) => {
        setSearchText(e.target.value);
        setColConfiguration(colConfiguration?.map(i => (i.columnKey !== 'action' && i.columnKey !== 'selectionCol' && i.columnKey !== 'dragCol') ?
            ({ ...i, isShow: i.columnName?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) }) : { ...i }));
    }, [colConfiguration]);

    // Move to top
    const handleMoveToTop = useCallback((e: any, columnKey: string) => {
        const colIndex: number = colConfiguration.findIndex(i => i.columnKey === columnKey);

        //excludes pinned columns
        const pinnedCols = colConfiguration?.filter(i => !i.isDraggable);

        const restCols = colConfiguration?.filter(i => i.isDraggable && i.columnKey !== columnKey);

        setColConfiguration([...pinnedCols, colConfiguration[colIndex], ...restCols?.map(i => ({ ...i, isShow: true }))]);
        setSearchText('');
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onMoveToIndex && onMoveToIndex(columnKey, pinnedCols?.length + 1);
    }, [colConfiguration, onMoveToIndex]);

    const getText = useCallback((originalText: string, sText: string) => {
        if (sText === '') return originalText;
        const len: number = sText.length;
        const index: number = originalText.toString().toLowerCase().indexOf(sText.toString().toLowerCase());
        return `${originalText.slice(0, index)}<span class='highlight'>${originalText.slice(index, index + len)}</span>${originalText.slice(index + len)}`;
    }, []);

    // Dragover event
    const onDragOver = (ev: any) => {
        ev.preventDefault();
    };

    // Drop event
    const onDrop = (e: any) => {
        //if the drop item is pinned, then no allow to drop
        const dropCol = colConfiguration?.filter(i => i.columnKey === e.currentTarget.id);
        if (dropCol && dropCol.length && !dropCol[0].isDraggable) return;

        let columnKey: string = e.dataTransfer.getData('columnKey');
        const tmp: IColumnConfiguration[] = [...colConfiguration];
        let dragItemIndex: number = colConfiguration.findIndex(i => i.columnKey === columnKey);
        const dragItem = tmp.splice(dragItemIndex, 1)[0];
        const dropItemIndex: number = tmp.findIndex(i => i.columnKey === e.currentTarget.id);
        tmp.splice(dropItemIndex, 0, dragItem);

        setColConfiguration([...tmp]);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onMoveToIndex && onMoveToIndex(columnKey, dropItemIndex);
    };

    // Drag start event
    const onDragStart = (ev: any, columnKey: string) => {
        ev.dataTransfer.setData('columnKey', columnKey);
    };

    const getColumn = (column: IColumnConfiguration) => {
        const columnKey: string = column.columnKey;
        const isVisible: boolean = column.visible;
        const isDraggable: boolean = column.isDraggable ?? true;

        return <li id={columnKey} draggable={isDraggable} key={columnKey} onDragStart={(e: any) => onDragStart(e, columnKey)} onDrop={onDrop}>
            <RowFilterList columnKey={columnKey} isDraggable={isDraggable} isChecked={isVisible} onCheckboxChange={handleCheckbox} onMoveUp={(e: any) => handleMoveToTop(e, columnKey)}>
                <span className="m-item" dangerouslySetInnerHTML={{ __html: getText(column.columnName, searchText) }} />
            </RowFilterList>
        </li>;
    };

    return <NbisFilterListWrapper className="filter-list-setting">
        <div className="column-setting-header">
            <HPHCheckbox className={!allSelect ? 'not-all-check' : ''} label="" checked={allSelect} onChange={handleAllCheckbox} />
            <div className="input-box">
                <input type="text" placeholder="Search..." value={searchText} onChange={handleChange} />
            </div>
        </div>
        <div className="column-setting-box">
            <ul onDragOver={onDragOver}>
                {colConfiguration
                    ?.filter(i => !['action', 'selectionCol', 'dragCol', 'frontaction'].includes(i.columnKey) && i.isShow)
                    ?.map(item => getColumn(item))
                }
            </ul>
        </div>
    </NbisFilterListWrapper>;
};