import type { SystemPreferenceEntity } from "domain/entity/SystemPreference/SystemPreferenceEntity";
import type { SystemPreferenceRepository } from "domain/repository/SystemPreference/SystemPreferenceRepo";
import _ from "lodash";
import type { SystemPreferenceModel } from "presentation/model/SystemPreference/SystemPreferenceModel";
import { Dispatch, SetStateAction } from "react";
import BaseViewModel from "../BaseViewModel";

interface SystemPreferenceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<SystemPreferenceModel>> | ((value: SetStateAction<SystemPreferenceModel>) => void),
    ],
    systemPreferenceRepo: SystemPreferenceRepository,
}

export const SystemPreferenceVM = ({ dispatch, systemPreferenceRepo }: SystemPreferenceVMProps) => {
    const [systemPreferenceDispatch] = dispatch;

    //initial
    const getTableSystemPreferences = async (entryPoint: string, tableId: string, userName: string) => {
        systemPreferenceDispatch(prevState => {
            return {
                ...prevState,
                allSystemPreferences: [],
                allGlobalSystemPreferences: [],
                allUserSystemPreferences: [],
            }
        })
        await systemPreferenceRepo.getSystemPreferences(entryPoint, tableId, userName).then(data => {
            if (data) {
                const newData = data?.map(systemPreference => {
                    systemPreference.contentForScrene = JSON.parse(systemPreference.content);
                    return systemPreference;
                });

                const allGlobalSystemPreferences = _.sortBy(newData?.filter(systemPreference => systemPreference.category === "GLOBAL"), "name");
                const allUserSystemPreferences = _.sortBy(newData?.filter(systemPreference => systemPreference.category === "USER"), "name");
                const activeSystemPreference = newData?.find(systemPreference => systemPreference.effectiveInd === 'Y');
                systemPreferenceDispatch(prevState => {
                    return {
                        ...prevState,
                        allSystemPreferences: newData,
                        allGlobalSystemPreferences: allGlobalSystemPreferences,
                        allUserSystemPreferences: allUserSystemPreferences,
                        activeSystemPreference: activeSystemPreference,
                        initialPreferenceState: true,
                    }
                })
            }
        }).catch(error => {
            systemPreferenceDispatch(prevState => {
                return {
                    ...prevState,
                    allSystemPreferences: [],
                    allGlobalSystemPreferences: [],
                    allUserSystemPreferences: [],
                }
            })
        });
    }

    //user
    const onCreateSystemPreferenceClick = () => {
        systemPreferenceDispatch(prevState => {
            return {
                ...prevState,
                isShowSaveNewConfirmModal: true
            }
        })
    }
    const onDeleteSystemPreferenceClick = () => {
        systemPreferenceDispatch(prevState => {
            return {
                ...prevState,
                isShowDeleteConfirmModal: true
            }
        })
    }

    const onDeleteSystemPreference = async (systemPreference: SystemPreferenceEntity) => {
        return systemPreferenceRepo.deleteSystemPreference(systemPreference).then((result) => {
            if (result) {
                systemPreferenceDispatch(prevState => {
                    const allGlobalSystemPreferences = prevState?.allGlobalSystemPreferences?.filter(preference => preference.name !== systemPreference.name);
                    const allUserSystemPreferences = prevState?.allUserSystemPreferences?.filter(preference => preference.name !== systemPreference.name);
                    return {
                        ...prevState,
                        allGlobalSystemPreferences: allGlobalSystemPreferences,
                        allUserSystemPreferences: allUserSystemPreferences,
                        isShowDeleteConfirmModal: false,
                    };
                });
            }
        }).catch((error) => {
            return systemPreferenceDispatch(prevState => {
                return {
                    ...prevState,
                    isShowDeleteConfirmModal: false,
                };
            });
        });
    }

    const onCreateSystemPreference = async (preference: SystemPreferenceEntity, isAdd: boolean) => {
        if (isAdd) {
            return await systemPreferenceRepo.createNewSystemPreference(preference).then((result) => {
                if (result && result.toString().startsWith("Error:")) {
                    systemPreferenceDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: { "SaveSystemPreferenceFail": result.toString() },
                            isShowSaveNewConfirmModal: false,
                        };
                    });
                    return { "SaveSystemPreferenceFail": result.toString() };
                } else {
                    systemPreferenceDispatch(prevState => {
                        return {
                            ...prevState,
                            isShowSaveNewConfirmModal: false,
                        };
                    });
                }
            })
        } else {
            return await systemPreferenceRepo.updateSystemPreference(preference).then((result) => {
                if (result && result.toString().startsWith("Error:")) {
                    systemPreferenceDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: { "SaveSystemPreferenceFail": result.toString() },
                            isShowSaveNewConfirmModal: false,
                        };
                    });
                    return { "SaveSystemPreferenceFail": result.toString() };
                } else {
                    systemPreferenceDispatch(prevState => {
                        return {
                            ...prevState,
                            isShowSaveNewConfirmModal: false,
                        };
                    });
                }
            })

        }
    }

    const setActiveSystemPreference = (preference: SystemPreferenceEntity) => {
        systemPreferenceDispatch(prevState => {
            return {
                ...prevState,
                activeSystemPreference: preference,
            }
        })
    }

    const closeSaveConfirmModal = () => {
        systemPreferenceDispatch(prevState => {
            return {
                ...prevState,
                isShowSaveNewConfirmModal: false,
            }
        })
    }

    const closeDeleteConfirmModal = () => {
        systemPreferenceDispatch(prevState => {
            return {
                ...prevState,
                isShowSaveNewConfirmModal: false,
            }
        })
    }

    return {
        getTableSystemPreferences: getTableSystemPreferences,
        onCreateSystemPreferenceClick: onCreateSystemPreferenceClick,
        onDeleteSystemPreferenceClick: onDeleteSystemPreferenceClick,
        onDeleteSystemPreference: onDeleteSystemPreference,
        onCreateSystemPreference: onCreateSystemPreference,
        setActiveSystemPreference: setActiveSystemPreference,
        closeSaveConfirmModal: closeSaveConfirmModal,
        closeDeleteConfirmModal: closeDeleteConfirmModal,
    }
}