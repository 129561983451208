import { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { MasterHoldMaintenanceConstant } from "./MasterHoldMaintenanceConstant";

const SCREEN_CONSTANT = MasterHoldMaintenanceConstant.Detail;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
export const INITIAL_MASTER_HOLD_DETAIL_COL_DEF: any[] = [
    {
        headerName: SCREEN_CONSTANT.STATE,
        field: "dtlState",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.CHARGE_TYPE,
        field: "chargeType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.SUB_CHARGE_TYPE,
        field: "subChargeType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.TARIFF_TYPE,
        field: "tariffype",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.TARIFF_CODE,
        field: "tariffCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.CHARGE_ON_COMPANY,
        field: "chargeOnCompany",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.BILL_TO_COMPANY,
        field: "billToCompany",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: SCREEN_CONSTANT.CONSORTIUM_CODE,
        field: "consortiumCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: SCREEN_CONSTANT.VESSEL_CODE,
        field: "vesselCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.VOYAGE_CODE,
        field: "voyageCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.ETD,
        field: "etd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
        dataType: "dateTime"
    },
    {
        headerName: SCREEN_CONSTANT.OPS_DATE,
        field: "opsDate",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
        dataType: "date"
    },
    {
        headerName: SCREEN_CONSTANT.CNTR_NO,
        field: "cntrNo",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: SCREEN_CONSTANT.SIZE,
        field: "cntrSize",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 120,
    },
    {
        headerName: SCREEN_CONSTANT.STATUS,
        field: "cntrStatus1",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 120,
    }
]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}
