export enum Permission {
    BUPROFILE = "UI.AUTH.BUPROFILE",
    LOGIN = "LOGIN",
    USER = "UI.ADMIN.USER",
    USERGROUP = "UI.ADMIN.USERGROUP",
    USERSETTING = "UI.ADMIN.USERSETTING",
    CHARGE_PROCESSING = "UI.CHAR.CHARGEPROCESSING",
    CHANGE_KEY_DATA = "UI.CHAR.CHANKEYDATAMAIN",
    MANUAL_CHARGE = "UI.CHAR.MANUALCHARGE",
    CHARGE_DEATIL_MAINTENANCE = "UI.CHAR.CHARGEDETAILMAINTENANCE",
    MANUAL_CHARGE_DETAIL = "UI.CHAR.MANUALCHARGEDETAIL",
    NON_OPS_CHARGE = "UI.CHAR.NONOPSCHARGE",
    NON_OPS_CHARGE_DETAIL = "UI.CHAR.NONOPSCHARGEDETAIL",
    CHARGE_CONFIRMED_MAIN = "UI.CHAR.CHARGECONFIRMED",
    CREDIT_NOTE = "UI.CHAR.CREDITNOTE",
    CREDIT_NOTE_DETAIL = "UI.CHAR.CREDITNOTEDETAIL",
    REQUISITION_FORM = "UI.CHAR.REQUISITIONFORM",
    RECAL_PROCESSGEN = "UI.CHAR.RECALPROCESSGEN",
    EMPTY_POOL_STORAGE_GEN = "UI.CHAR.EMPTYPOOLSTORAGEGEN",
    DOCUMENT_APPROVAL = "UI.DOC.DOCUMENTAPPROVAL",
    DOCUMENT_PREVIEW = "UI.CHAR.INVOICE",
    DOCUMENT_PREVIEW_DISAPPROVE = "UI.CHAR.INVOICEDISAPPROVE",
    DOCUMENT_PREVIEW_APPROVE = "UI.CHAR.INVOICEAPPROVE",
    DOCUMENT_PREVIEW_DETAIL = "UI.CHAR.INVOICEDETAIL",
    SUPPLEMENTARY_INVOICE = "UI.CHAR.SUPPLEMENTARYINVOICE",
    SUPPLEMENTARY_INVOICE_DETAIL = "UI.CHAR.SUPPLEMENTARYINVOICEDETAIL",
    DOCUMENT_EDI_MONITORING = "UI.DOC.EDIMONITORING",
    DOCUMENT_ENQUIRY_DETAIL = "UI.DOC.DOCUMENTENQUIRYDETAIL",
    PROPOSAL_ENQUIRY_DETAIL = "UI.PROP.PROPOSALENQUIRYDETAIL",
    PROPOSAL_ENQUIRY_ACTIVATE = "UI.PROP.PROPOSALENQUIRYACTIVATE",
    PROPOSAL_ENQUIRY_DEACTIVATE = "UI.PROP.PROPOSALENQUIRYDEACTIVATE",
    STANDARD_PROPOSAL_MAINTENANCE = "UI.AGREE.STANDARDPROPOSALMAIN",
    STANDARD_PROPOSAL_REJECT = "UI.AGREE.STANDARDPROPOSALREJECT",
    STANDARD_PROPOSAL_APPROVE = "UI.AGREE.STANDARDPROPOSALAPPROVE",
    STANDARD_PROPOSAL_DETAIL_MAINTENANCE = "UI.AGREE.STANDARDPROPOSALDETAILMAIN",
    GENERAL_PROPOSAL_MAINTENANCE = "UI.AGREE.GENERALPROPOSALMAIN",
    GENERAL_PROPOSAL_DETAIL_MAINTENANCE = "UI.AGREE.GENERALPROPOSALDETAILMAIN",
    GENERAL_PROPOSAL_REJECT = "UI.AGREE.GENERALPROPOSALREJECT",
    GENERAL_PROPOSAL_APPROVE = "UI.AGREE.GENERALPROPOSALAPPROVE",
    TARIFF_NATURE_MAINTENANCE = "UI.CODE.TARIFFNATUREMAIN",
    TARIFF_TYPE_MAINTENANCE = "UI.CODE.TARIFFTYPEMAIN",
    TARIFF_COMPONENT_MAINTENANCE = "UI.TARIFF.TARIFFCOMPONENTMAIN",
    TARIFF_CODE_MAINTENANCE = "UI.TARIFF.TARIFFCODEMAIN",
    TARIFF_CODE_BY_COMPANY_MAINTENANCE = "UI.TARIFF.TARIFFCODEBYCOMPANYMAIN",
    COMPANY_MAINTENANCE = "UI.COMP.COMPANYMAINTENANCE",
    COMPANY_DETAIL_MAINTENANCE = "UI.COMP.COMPANYDETAILMAINTENANCE",
    BILL_TO_COMPANY_MAPPING_MAINTENANCE = "UI.CHAR.BILLTOCOMPANYMAPPINGMAINTENANCE",
    CHARGE_ON_COMPANY_MAPPING_MAINTENANCE = "UI.CHAR.CHARGEONCOMPANYMAPPINGMAINTENANCE",
    BILLING_TYPE_MAPPING_MAINTENANCE = "UI.CHAR.BILLINGTYPEMAPPINGMAINTENANCE",
    EQUIPMENT_CODE_IND_MAPPING_MAINTENANCE = "UI.CHAR.EQUIPMENTCODEINDMAPPINGMAINTENANCE",
    MASTER_CODE_MAINTENANCE = "UI.CODE.MASTERCODEMAINTENANCE",
    SPECIAL_HANDLING_IND_MAPPING_MAINTENANCE = "UI.CHAR.SPECIALHANDLINGINDMAPPINGMAINTENANCE",
    BILLING_CYCLE_MAINTENANCE = "UI.CODE.BILLINGCYCLEMAINTENANCE",
    CHARGE_TYPE_MAINTENANCE = "UI.CODE.CHARGETYPEMAINTENANCE",
    SHIFT_MAINTENANCE = "UI.CODE.SHIFTMAINTENANCE",
    GROUP_MAINTENANCE = "UI.CODE.GROUPMAINTENANCE",
    CALENDAR_EVENT_MAINTENANCE = "UI.CODE.CALENDAREVENTMAINTENANCE",
    STAFF_VSL_CODE_MAINTENANCE = "UI.CODE.STAFFVSLCODEMAINTENANCE",
    COMMON_ACCOUNT_MAINTENANCE = "UI.CHAR.COMMONACCOUNTMAINTENANCE",
    CHARGE_QTY_ADJ_MAINTENANCE = "UI.CHAR.CHARGEQTYADJMAINTENANCE",
    CHARGE_CRITERIA_MAINTENANCE = "UI.CHARSET.CHARCRITMAIN",
    GENERAL_CHARGE_CRITERIA_MAINTENANCE = "UI.CHARSET.GENECHARGENECRITMAIN",
    CHARGE_EXCLMAIN_CRITERIA_MAINTENANCE = "UI.CHARSET.CHAREXCLMAIN",
    EMPTY_POOL_EXCL_CRITERIA_MAINTENANCE = "UI.CHARSET.EMPTPOOLEXCLMAIN",
    MARSHALLING_TYPE_EXCL_CRITERIA_MAINTENANCE = "UI.CHARSET.MARSTYPEEXCLCRITMAIN",
    COMPANY_FSP_REJECT = "UI.COMP.COMPANYFSPREJECT",
    COMPANY_FSP_APPROVE = "UI.COMP.COMPANYFSPAPPROVE",
    COMPANY_EMPTY_POOL_REJECT = "UI.COMP.COMPANYEMPTYPOOLREJECT",
    COMPANY_EMPTY_POOL_APPROVE = "UI.COMP.COMPANYFSPEMPTYPOOLAPPROVE",
    COMPANY_CHARGE_SUPPLE_TERMS_REJECT = "UI.COMP.COMPANYCHARGESUPPLETERMSREJECT",
    COMPANY_CHARGE_SUPPLE_TERMS_APPROVE = "UI.COMP.COMPANYCHARGESUPPLETERMSAPPROVE",
    DOCUMENT_SEPARATION_CRITERIA_MAINTENANCE = "UI.DOCSET.DOCUSEPACRITMAIN",
    DOCUMENT_GROUPING_CRITERIA_MAINTENANCE = "UI.DOCSET.DOCUGROUCRITMAIN",
    TARIFF_MAPPING_MAINTENANCE = "UI.CHAR.TARIFFMAPPINGMAINTENANCE",
    TRANSACTION_TYPE_SETTING_MAINTENANCE = "UI.DOCSET.TRANSTYPESETTINGMAIN",
    CURRENCY_EXCHANGE_MAINTENANCE = "UI.DOCSET.EXCHANGERATEMAIN",
    PRINT_QUEUE_MAINTENANCE = "UI.DOCSET.PRINTQUEUEMAIN",
    EMAIL_TEMPLATE_MAINTENANCE = "UI.DOCSET.EMAILTEMPLATEMAIN",
    EDI_ITEM_CODE_MAPPING_MAINTENANCE = "UI.EDISET.EDIITEMCODEMAIN",
    COST_CENTER_CODE_MAPPING_MAINTENANCE = "UI.EDISET.COSTCENTERCODEMAIN",
    TARIFF_COA_MAPPING_MAINTENANCE = "UI.CHAR.TARIFFCOAMAPPINGMAINTENANCE",
    ACTIVITY_LOG_MAINTENANCE = "UI.SYSTEM.ACTIVITYLOG",
    DISCOUNT_MAPPING_MAINTENANCE = "UI.CHAR.DISCOUNTMAPPINGMAINTENANCE",
    DISCOUNT_MAPPING_REJECT = "UI.INV.DISCOUNTMAPPINGREJECT",
    DISCOUNT_MAPPING_APPROVE = "UI.INV.DISCOUNTMAPPINGAPPROVE",
    TAX_MAPPING_MAINTENANCE = "UI.CHAR.TAXMAPPINGMAINTENANCE",
    CUSTOMER_ENQUIRY_MAINTENANCE = "UI.COMP.CUSTOMERENQUIRYMAINTENANCE",
    PORT_MAINTENANCE = "UI.CODE.PORTMAINTENANCE",
    MASTER_HOLD_MAINTENANCE = "UI.DOCSET.MASTERHOLDMAINTENANCE",
    DOCUMENT_ISSUANCE_MAINTENANCE = "UI.DOCSET.DOCUMENTISSUANCEMAINTENANCE",
    PARAMETER_MAINTENANCE = "UI.SUPPORT.PARAMETERMAINTENANCE",
    INTERNAL_CORRECTION_MAINTENANCE = "UI.REPO.INTECORRMAIN",
    CHARGE_DATA_ENQUIRY = "UI.CHAR.CHARGEDATAENQUIRY",
    DOCUMENT_ENQUIRY = "UI.DOC.DOCUMENTENQUIRY",
    PROPOSAL_ENQUIRY = "UI.PROP.PROPOSALENQUIRY",
    AUDIT_TRAIL_REPORT_MAINTENANCE = "UI.REPO.AUDITTRAILREPORTMAINTENANCE",
    GENERAL_REPORT_MAINTENANCE = "UI.REPO.GENERALREPORTMAINTENANCE",
    FINANCIAL_REPORT_MAINTENANCE = "UI.REPO.FINANCIALREPORTMAINTENANCE",
    REVENUE_RECONCILIATION_REPORT_MAINTENANCE = "UI.REPO.REVENUERECONCILIATIONREPORTMAINTENANCE",
    MONTH_END_CLOSURE_MAINTENANCE = "UI.REPO.MONTHENDCLOSUREMAINTENANCE",
}