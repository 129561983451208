import type { IDateFilterParams, INumberFilterParams, ISetFilterParams, ITextFilterParams } from "ag-grid-community";
import moment from "moment";
import { ColorCircle, ColorLabel, ColorPickerWrapper } from "presentation/view/components/styled/NbisTable.styled";
import type { NbisTableProps } from "presentation/view/components/TableWrapper/NbisTableConstant";
import { ColorPicker, IconButton } from "veronica-ui-component/dist/component/core";


/* istanbul ignore next */
export const getColumnFilterByDataType = (dataType: string) => {
    switch (dataType) {
        case 'text':
            return 'agTextColumnFilter';
        case 'select':
            return 'agTextColumnFilter';
        case 'dateTime':
            return 'agDateColumnFilter';
        case 'boolean':
            return 'agSetColumnFilter';
        case 'date':
            return 'agDateColumnFilter';
        case 'color':
            return 'agSetColumnFilter';
        case 'number':
            return 'agNumberColumnFilter';
        default:
            return 'agTextColumnFilter';
    }
};

/* istanbul ignore next */
export const sortingComparator = (valueA: any, valueB: any) => {
    if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueA?.toLowerCase()?.localeCompare(valueB?.toLowerCase());
    } else if (typeof valueA === 'number' && typeof valueB === 'number') {
        return valueA - valueB;
    } else {
        let valA = typeof valueA === 'string' ? valueA : moment(valueA).format('YYYY-MM-DD MM:HH');
        let valB = typeof valueB === 'string' ? valueB : moment(valueB).format('YYYY-MM-DD MM:HH');
        return valA?.toLowerCase()?.localeCompare(valB?.toLowerCase());
    }
};

/* istanbul ignore next */
const onActionButtonHandler = (item: any, rowData: any, params: any, props: NbisTableProps) => {
    return (
        item.id && props[item.id] && props[item.id]({ rowData: rowData, item: item }),
        params?.api?.redrawRows()
    );
};

/* istanbul ignore next */
const onDelete = (params: any, props: NbisTableProps) => {
    params?.api?.applyTransaction({ remove: [params.node.data] });
    return props.onDelete && props.onDelete(params.node.data);
};



/* istanbul ignore next */
export const initColumnBodyTemplate = (params: any, props: NbisTableProps) => {
    const { editMode, showDeleteButton, editable, showActionButtons } = props;
    const column: any = params.colDef;
    const cellValue: any = params.value ? params.value : '';
    const isRowDisabled = params?.data?.flag_disabled === true;

    //add for pivot table
    if (typeof cellValue === 'object' && cellValue !== null && 'value' in cellValue) {
        return cellValue.value;
    }

    //add for pivot group column
    if (column.colId === "ag-Grid-AutoColumn") {
        return cellValue;
    }

    if (column.dataType === 'dateTime') {
        if (column.valueFormatter) {
            return column.valueFormatter(params);
        }
        const val: any = moment(cellValue)?.format('DD/MM/YYYY HH:mm');
        return val === 'Invalid date' ? '' : val;
    } else if (column.dataType === 'date') {
        if (column.valueFormatter) {
            return column.valueFormatter(params);
        }
        let val = moment(cellValue)?.format('DD/MM/YYYY');
        return val === 'Invalid date' ? '' : val;
    } else if (column.dataType === 'color') {
        return <ColorPicker label="" value={cellValue} toolTipText="Help Icon" disabled={true} />;
    } else if (column?.dataType === 'select') {
        let option = column?.options?.filter((item: any) => item.value === cellValue)[0];
        return option ? option?.label : '';
    } else if (column.dataType === 'boolean') {
        return cellValue && <IconButton iconClassName='tick-icon' fileName="Icon-tick" size="medium" tooltipDisable={true} />;
    } else if (column.colId === 'frontaction') {
        return (<div>
            {params.data?.frontActionButtons?.map(((item: any) => {
                return (<IconButton key={item?.id} fileName={item?.icon} size="medium"
                    {...item.disableTooltip ? { tooltipDisable: true } : { tooltipDisable: false }}
                    toolTipArrow={item.toolTipArrow ? item.toolTipArrow : false}
                    toolTipPlacement={item?.toolTipPlacement || 'left'}
                    toolTipText={item?.title}
                    onClick={() => onActionButtonHandler(item, params.data, params, props)}
                />);
            }))}
        </div>);
    } else if (column.colId === 'dragCol') {
        return '';
    } else if (column.colId === 'selectionCol') {
        return '';
    } else if (column.dataType === 'text' || column.dataType === 'number') {
        if (column.valueFormatter) {
            return column.valueFormatter(params);
        }
        return cellValue;
    } else {
        let editingCells = params?.api?.getEditingCells();
        let isCurrentRowEditing = editingCells?.some((cell: any) => {
            return cell.rowIndex === params.node.rowIndex;
        });
        if (isCurrentRowEditing && props.editMode === 'row') {
            return (
                <div className='edit-icons'>
                    <IconButton fileName="Icon-tick" disabled={isRowDisabled} size="medium" tooltipDisable={true} onClick={() => params?.api?.stopEditing(false)} />
                    <IconButton fileName="Icon-cross" disabled={isRowDisabled} size="medium" tooltipDisable={true} onClick={() => params?.api?.stopEditing(true)} />
                </div>);
        } else {
            return (
                <div className='actionIcons'>
                    {editMode === 'row' && editable && <IconButton fileName="Icon-pen" size="medium" tooltipDisable={true}
                        disabled={isRowDisabled}
                        onClick={() => {
                            let filteredColumns = params?.api?.getDisplayedCenterColumns()?.filter((i: any) => i?.colId !== 'dragCol' && i?.colId !== 'selectionCol')!;
                            params?.api?.startEditingCell({
                                rowIndex: params.node.rowIndex,
                                colKey: filteredColumns[0]?.colId,
                            });
                        }} />}
                    {showDeleteButton && <IconButton fileName="Icon-trash" size="medium" tooltipDisable={true} disabled={isRowDisabled} onClick={() => onDelete(params, props)} />}
                    {showActionButtons && params.data?.actionButtons?.map(((item: any) => {
                        return (<IconButton key={item?.id} fileName={item?.icon} size="medium"
                            {...item.disableTooltip ? { tooltipDisable: true } : { tooltipDisable: false }}
                            toolTipArrow={item.toolTipArrow ? item.toolTipArrow : false}
                            toolTipPlacement={item?.toolTipPlacement || 'left'}
                            toolTipText={item?.title}
                            disabled={isRowDisabled}
                            onClick={() => onActionButtonHandler(item, params.data, params, props)}
                        />);
                    }))}
                </div>);
        }
    }
};

/* istanbul ignore next */
export const colorFilterItemTemplate = (option: any) => {
    return (
        <ColorPickerWrapper>
            <ColorCircle style={{ backgroundColor: option.value }}></ColorCircle>
            <ColorLabel>{option.value}</ColorLabel>
        </ColorPickerWrapper>
    );
};

/* istanbul ignore next */
export const booleanFilterItemTemplate = (option: any) => {
    if (option.value === 'true') {
        return <IconButton size='small' tooltipDisable={true} fileName='Icon-tick' />;
    } else if (option.value === 'false') {
        return '(Blank)';
    } else {
        return option.value;
    }
};

/* istanbul ignore next */
export const initFilterTemplate = (params: any) => {
    const column: any = params.colDef;

    if (column.dataType === 'select') {
        const filterOptions: ITextFilterParams =
        {
            buttons: ['clear'],
            defaultOption: 'contains',
            textMatcher: ({ filterOption, value, filterText }: { filterOption: any, value: any, filterText: any }) => {
                const filterTextLowerCase = filterText?.toLowerCase();
                let x: any = column?.options?.filter((option: any) => option?.value?.toLowerCase() === value)[0]?.label;
                const valueLowerCase = x?.toString()?.toLowerCase();
                switch (filterOption) {
                    case 'contains': return valueLowerCase.indexOf(filterTextLowerCase) >= 0;
                    case 'notContains': return valueLowerCase.indexOf(filterTextLowerCase) === -1;
                    case 'equals': return valueLowerCase === filterTextLowerCase;
                    case 'notEqual': return valueLowerCase !== filterTextLowerCase;
                    case 'startsWith': return valueLowerCase.indexOf(filterTextLowerCase) === 0;
                    case 'endsWith':
                        var index = valueLowerCase.lastIndexOf(filterTextLowerCase);
                        return index >= 0 && index === (valueLowerCase.length - filterTextLowerCase.length);
                    default: return false;
                }
            },
            maxNumConditions: 2,
        };
        return filterOptions;
    } else if (column.dataType === 'text') {
        const filterOptions: ITextFilterParams = {
            buttons: ['clear'],
            defaultOption: 'equals',
            maxNumConditions: 2,
        };
        return filterOptions;
    } else if (column.dataType === 'number') {
        const filterOptions: INumberFilterParams = {
            buttons: ['clear'],
            defaultOption: 'equals',
            maxNumConditions: 2,
        };
        return filterOptions;
    } else if (column.dataType === 'dateTime') {
        const filterOptions: IDateFilterParams = {
            buttons: ['clear'],
            defaultOption: 'equals',
            comparator: (filterLocalDate: Date, cellValue: any) => {
                let filterBy = moment(filterLocalDate).format('DD-MM-YYYY');
                let filterMe = moment(cellValue).format('DD-MM-YYYY');
                if (filterBy === filterMe) {
                    return 0;
                }
                if (filterMe < filterBy) {
                    return -1;
                }
                if (filterMe > filterBy) {
                    return 1;
                }
                return 0;
            },
            maxNumConditions: 2,
        };
        return filterOptions;
    } else if (column.dataType === 'date') {
        const filterOptions: IDateFilterParams = {
            buttons: ['clear'],
            defaultOption: 'equals',
            comparator: (filterLocalDate: any, cellValue: any) => {
                filterLocalDate = new Date(filterLocalDate);
                cellValue = new Date(cellValue);
                let filterBy = filterLocalDate.getTime();
                let filterMe = cellValue.getTime();
                if (filterBy === filterMe) {
                    return 0;
                }
                if (filterMe < filterBy) {
                    return -1;
                }
                if (filterMe > filterBy) {
                    return 1;
                }
                return 0;
            },
            maxNumConditions: 2,
        };
        return filterOptions;
    } else if (column.dataType === 'color') {
        const filterOptions: ISetFilterParams = {
            buttons: ['clear'],
            cellRenderer: colorFilterItemTemplate,
        };
        return filterOptions;
    } else if (column.dataType === 'boolean') {
        const filterOptions: ISetFilterParams =
        {
            cellRenderer: booleanFilterItemTemplate,
            suppressMiniFilter: true,
        };
        return filterOptions;
    } else {
        const filterOptions: ITextFilterParams =
        {
            buttons: ['clear'],
            maxNumConditions: 2,
        };
        return filterOptions;
    }
};

/* istanbul ignore next */
export const onRowEditingStarted = (params: any) => {
    params?.api?.refreshCells({
        columns: ['action'],
        rowNodes: [params.node],
        force: true,
    });
};

/* istanbul ignore next */
export const onRowEditingStopped = (params: any) => {
    params?.api?.refreshCells({
        columns: ['action'],
        rowNodes: [params.node],
        force: true,
    });
};

/* istanbul ignore next */
export const onCellEditingStopped = (params: any, data: any, setData: any) => {
    if (params.valueChanged) {
        params.data.flag_edit = true;
        let newData: any = {};
        newData = data?.map((item: any, i: any) => {
            if (params.rowIndex === i) {
                return params.data;
            }
            return item;
        });
        setData(newData);

        params?.api?.redrawRows();
        params?.api?.ensureIndexVisible(params.rowIndex, 'top');
    }
};