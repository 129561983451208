import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { MenuItem } from "./RightClickMenuWithMoveAfter";

export const generateMenuItems = (
    selectedRows: any[],
    isConfirmMove: boolean = false,
    menuItems: MenuItem[] = [],
    onMoveConfirm: () => void,
    onCancel: () => void,
    onMoveAfter: () => void,
): MenuItem[] => {
    let newMenuItems: MenuItem[] = [];
    if (isConfirmMove) {
        newMenuItems = [
            ...menuItems,
            {
                label: WorkspaceConstant.Common.MENU_MOVE_CONFIRM,
                command: onMoveConfirm,
                disabled: selectedRows?.length !== 1
            },
            {
                label: WorkspaceConstant.Common.BUTTON_CANCEL,
                command: onCancel
            }
        ];
    } else {
        newMenuItems = [
            ...menuItems,
            {
                label: WorkspaceConstant.Common.MENU_MOVE_AFTER,
                command: onMoveAfter,
                disabled: selectedRows?.length < 1
            }
        ];
    }

    return newMenuItems;
}

export const applyMoveAfter = (selectedRows: any[], allRows: any[], indexName: string) => {
    if (selectedRows && allRows) {
        const selectedValues = new Set(selectedRows?.map(row => row[indexName]));

        allRows = allRows?.filter(row => !selectedValues.has(row[indexName]))?.map(row => ({ ...row, flag_showCutCursor: true }));
    }
    return allRows;
}

export const applyMoveConfirm = (cuttedRows: any[], selectedRow: any, allRows: any[], indexName: string = "index", priorityName: string = "priority") => {
    let updatedRows: any[] = [];
    if (cuttedRows && selectedRow && allRows) {
        const selectedIndex = allRows.findIndex(row => String(row[indexName]) === String(selectedRow[indexName]));
        if (selectedIndex !== -1) {
            allRows.splice(selectedIndex + 1, 0, ...cuttedRows);

            allRows?.forEach((row, index) => {
                if (row[priorityName] !== index + 1) {
                    row = {
                        ...row,
                        [priorityName]: index + 1
                    };
                    updatedRows.push(row);
                }
            });
        }
    }
    updatedRows?.forEach(row => row.flag_showCutCursor = false);
    return updatedRows;
}

export const changeCursor = (tableId: string, showMoveCursor: boolean) => {
    // const tableElement = document.querySelector<HTMLElement>(`#${tableId}`);

    // if (tableElement) {
    //     const gridCells = tableElement.querySelectorAll('[role="gridcell"]');

    //     if (showMoveCursor) {
    //         if (!tableElement.classList.contains('im-table-wrapper-move-cursor')) {
    //             tableElement.classList.add('im-table-wrapper-move-cursor');
    //         }
    //         gridCells?.forEach((cell: Element) => {
    //             const cellElement = cell as HTMLElement;
    //             if (!cellElement.classList.contains('im-table-wrapper-move-cursor')) {
    //                 cellElement.classList.add('im-table-wrapper-move-cursor');
    //             }
    //         });
    //     } else {
    //         if (tableElement.classList.contains('im-table-wrapper-move-cursor')) {
    //             tableElement.classList.remove('im-table-wrapper-move-cursor');
    //         }
    //         gridCells?.forEach((cell: Element) => {
    //             const cellElement = cell as HTMLElement;
    //             if (cellElement.classList.contains('im-table-wrapper-move-cursor')) {
    //                 cellElement.classList.remove('im-table-wrapper-move-cursor');
    //             }
    //         });
    //     }
    // } else {

    // }
};