import { SelectionChangedEvent } from "ag-grid-community";
import { MasterHoldEntity } from "domain/entity/MasterHold/MasterHoldEntity";
import _ from "lodash";
import { INITIAL_MASTER_HOLD_COL_DEF, transferRowData } from "presentation/constant/MasterHold/MasterHoldMaintenanceColumnDefinition";
import { MasterHoldMaintenanceConstant } from "presentation/constant/MasterHold/MasterHoldMaintenanceConstant";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { useMasterHoldMaintenanceVM } from "presentation/hook/MasterHold/useMasterHoldMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useMasterHoldMaintenanceTracked } from "presentation/store/MasterHold/MasterHoldMaintenanceProvider";
import { ConfirmDeleteModal } from "presentation/view/components/ConfirmDeleteModal";
import RightClickMenuWithMoveAfter from "presentation/view/components/NbisRightClickMenu/RightClickMenuWithMoveAfter";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";

const MasterHoldTablePanel: React.FC = () => {
    const [masterHoldState] = useMasterHoldMaintenanceTracked();
    const [anainfoState] = useANAInfoTracked();
    const masterHoldVM = useMasterHoldMaintenanceVM();
    const messageBarVM = useMessageBarVM();

    const gridRef: any = useRef(null);
    const rightClickRef: any = createRef();
    const { allowUpdate } = anainfoState;

    // for right click menu with move after begin
    const [showMoveCursor, setShowMoveCursor] = useState<boolean>(false);
    const [allRows, setAllRows] = useState<MasterHoldEntity[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [initialAllRows, setInitialAllRows] = useState<boolean>(true);
    const { isShowDeleteModal, selectedRows } = masterHoldState;


    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();

        masterHoldVM.updateSelectedRows(selectedRows);
    }, [masterHoldVM])

    useEffect(() => {
        const handleRightClickMenu = (event: any) => {
            if (_.isEmpty(masterHoldState.selectedRows) || !allowUpdate) {
                return;
            }
            event.preventDefault();
            rightClickRef.current.show(event);
        };
        document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

        return () => {
            document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
        };
    }, [rightClickRef, masterHoldState.selectedRows, allowUpdate]);

    const handleRowDoubleClick = useCallback((entity: MasterHoldEntity) => {
        masterHoldVM.onRowDoubleClick(entity);
    }, [masterHoldVM])

    const getAllRows = useCallback(() => {
        return allRows;
    }, [allRows]);

    const getSelectedRows = useCallback(() => {
        return masterHoldState.selectedRows;
    }, [masterHoldState.selectedRows]);

    // for right click menu with move after begin
    const onRefreshRow = useCallback((newAllRows: any[]) => {
        setAllRows(newAllRows);
        masterHoldVM.updateSelectedRows([]);
        setShowMoveCursor(true);
    }, [masterHoldVM]);

    const onMoveCancel = useCallback(() => {
        setInitialAllRows(true);
        setAllRows(masterHoldState.tableData);
        masterHoldVM.updateSelectedRows([]);
        setShowMoveCursor(false);
    }, [masterHoldState.tableData, masterHoldVM]);

    const onSaveMove = useCallback(async (newAllRows: any[]) => {
        if (_.isEmpty(newAllRows)) return;

        setIsLoading(true);

        masterHoldVM.onApply(newAllRows).then((res) => {
            if (!res || !res.success) {
                messageBarVM.showError('Move failed.');
                setInitialAllRows(true);
                setIsLoading(false);

                masterHoldVM.updateSelectedRows([]);
                setShowMoveCursor(false);
            } else {
                masterHoldVM.searchMasterHold().then((data) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    masterHoldVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                }).catch((e) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    masterHoldVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                })
            }
        })
    }, [masterHoldVM, messageBarVM]);

    const handleAdd = useCallback(() => {
        masterHoldVM.onAddClick();
    }, [masterHoldVM]);

    const handleDeleteClick = useCallback(() => {
        if (selectedRows.length < 1) {
            messageBarVM.showError("Please select a record(s) to delete.");
            return;
        }
        masterHoldVM.onDeleteClick();
    }, [masterHoldVM, messageBarVM, selectedRows.length]);

    const handleDelete = useCallback(() => {
        setIsLoading(true);
        masterHoldVM.onDelete(selectedRows).then(() => {
            masterHoldVM.searchMasterHold().then(() => {
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false)
            })
        }).catch((error) => {
            setIsLoading(false)
            messageBarVM.showError(error.message)
        })

    }, [masterHoldVM, messageBarVM, selectedRows]);

    const handleCancel = useCallback(() => {
        masterHoldVM.onCancelDelete();
    }, [masterHoldVM])

    const tbBtns = useMemo(() => {
        let btns = [];
        btns.push({
            id: 'onDeleteButton',
            icon: 'Icon-trash',
            title: 'Delete'
        })
        return btns;
    }, [])

    const memoMasterHoldTable = useMemo(() => {
        return (
            <>
                {(allowUpdate) && <RightClickMenuWithMoveAfter disabled={_.isEmpty(getSelectedRows())}
                    rightClickRef={rightClickRef} selectedRows={getSelectedRows()} allRows={getAllRows()}
                    onSaveMove={onSaveMove} onCancel={onMoveCancel} onRefreshRow={onRefreshRow}
                    showConfirmMove={showMoveCursor} priorityName="priority" />}
                <div id="myDiv">
                    <NbisTable
                        id='master-hold-table'
                        isNewColumnSetting={true}
                        columns={INITIAL_MASTER_HOLD_COL_DEF?.slice()}
                        data={transferRowData(allRows) ?? []}
                        headerActionButtons={tbBtns}
                        showPaginator={false}
                        editable={false}
                        showHeaderIcons={true}
                        showAddIcon={true}
                        showDeleteButton={false}
                        showReloadIcon={false}
                        isScrollHighlighted={true}
                        isRowHighligted={true}
                        selectionMode={true}
                        showCutCursor={showMoveCursor}
                        rowSelection={showMoveCursor ? "single" : "multiple"}
                        handleSelectionChanged={handleSelectionChange}
                        onRowDoubleClick={(e: any, entity: MasterHoldEntity) => handleRowDoubleClick(entity)}
                        gridHeight="customHeight"
                        customHeight="calc(100vh - 90px)"
                        ref={gridRef}
                        onAddClick={handleAdd}
                        onDeleteButton={handleDeleteClick}
                    />
                    <ConfirmDeleteModal headerText={MasterHoldMaintenanceConstant.Header.TITLE}
                        contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
                        visible={isShowDeleteModal}
                        onCancelClick={handleCancel}
                        onDeleteClick={handleDelete} />
                </div>
            </>
        );
    }, [allowUpdate, getSelectedRows, rightClickRef, getAllRows, onSaveMove, onMoveCancel, onRefreshRow, showMoveCursor, allRows, handleSelectionChange, handleRowDoubleClick, handleAdd, handleDeleteClick, handleCancel, handleDelete, isShowDeleteModal, tbBtns])

    useEffect(() => {
        if (masterHoldState.tableData && !_.isEmpty(masterHoldState.tableData) && (initialAllRows || allRows.length > masterHoldState.tableData.length)) {
            setAllRows(masterHoldState.tableData?.map((masterHoldEntity, index) => ({
                ...masterHoldEntity,
                index: index ?? 0,
                flag_showCutCursor: false
            })));
            setInitialAllRows(false);
        }
    }, [allRows, initialAllRows, masterHoldState.tableData]);

    return <><TableWrapper>
        {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
        {memoMasterHoldTable}
        {masterHoldState.forceRefresh && <></>}
    </TableWrapper>
    </>;
}

export default memo(MasterHoldTablePanel);