import _ from 'lodash';
import { AutoCompleteChangeParams } from 'primereact/autocomplete';
import { VirtualScrollerLazyParams } from 'primereact/virtualscroller';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { DynamicProp } from 'veronica-ui-component/dist/common/commonInterface';
import { Icons } from 'veronica-ui-component/dist/common/Icon';
import { HPHSkyBlue } from 'veronica-ui-component/dist/component/core/Colors';
import { IconButton } from 'veronica-ui-component/dist/component/core/IconButtons/IconButtons';
import { Lozenges } from 'veronica-ui-component/dist/component/core/Lozenges/Lozenges';
import {
    AutoCompleteGlobalStyles, StyledAutoComplete, StyledInputErrorMsg, StyledInputLabel,
    StyledInputWrapper,
    StyledLozengesContainer,
    StyledMenuItemTemplate, StyledMenuItemTemplateIcon,
    StyledMenuItemTemplateValue,
    StyledOptional,
    StyledRequired,
} from './styled/NbisInputDropDown.styled';

// Define types for options and props
export interface DropdownOption {
    dropdownLabel?: string;
    value?: string | number;
    tagLabel?: string | number;
    icon?: string;
    isMaster?: boolean;
    lozengesLabel?: string;
    lozengesVariation?: string;
    showText?: string;
    [key: string]: any;
}

interface VirtualScrollerOptions extends DynamicProp {
    showLoader?: boolean;
    delay?: number;
    lazy?: boolean;
    onLazyLoad?(e: VirtualScrollerLazyParams): void;
}

export interface InputDropdownProps extends DynamicProp {
    id?: string;
    options?: DropdownOption[];
    freeTextInput?: boolean;
    mode?: 'single' | 'multiple';
    virtualScrollerOptions?: VirtualScrollerOptions;
    onChange?(value: DropdownOption | DropdownOption[] | null): void;
    onDropdownClick?(): void;
    inputType?: 'number' | 'text' | 'freeText';
    field?: string;
    width?: string;
    arrowBackground?: 'transparent' | 'white';
    disableInput?: boolean;
    sort?: boolean;
    scrollHeight?: string;
    isVirtualScroll?: boolean;
    label?: string;
    required?: boolean;
    optional?: boolean;
    errorMessage?: string;
    helpIcon?: boolean;
    toolTipText?: string;
    placeholder?: string;
    value?: DropdownOption | DropdownOption[] | string | null;
}

const NbisInputDropDown: React.FC<InputDropdownProps> = ({
    id = 'inputDropdownId',
    options = [],
    freeTextInput = false,
    mode = 'single',
    virtualScrollerOptions = {
        delay: 0,
        lazy: true,
        showLoader: true
    },
    onChange,
    onDropdownClick,
    inputType = 'freeText',
    field = 'dropdownLabel',
    width = '250px',
    arrowBackground = 'white',
    disableInput = false,
    sort = true,
    scrollHeight,
    isVirtualScroll = true,
    label = 'Label',
    required = false,
    optional = false,
    errorMessage = '',
    helpIcon = false,
    toolTipText = 'Helper Message',
    placeholder = '',
    value: propsValue = null,
    ...rest
}) => {
    const [selectedValue, setSelectedValue] = useState<any>(null);
    const [filteredList, setFilteredList] = useState<DropdownOption[]>([]);
    const [panelLeft, setPanelLeft] = useState<number | null>(null);
    const [tempValue, setTempValue] = useState<string>('');
    const elemRef = useRef<any>();
    const inputLabel = useRef<HTMLLabelElement>(null);
    const [propOptions, setPropOptions] = useState<DropdownOption[]>([]);

    const searchList = useCallback(({ query }: { query: string }) => {
        let sortedOptions = sort ? [...propOptions].sort((a, b) => a?.dropdownLabel!.localeCompare(b?.dropdownLabel!)) : [...propOptions];

        if (query === '' && tempValue === '') {
            setFilteredList([...sortedOptions]);
        } else if ((query !== '' || tempValue !== '') && query !== tempValue) {
            const searchText = query !== '' ? query : tempValue;
            const tmpfilteredList = sortedOptions?.filter(item => (item.dropdownLabel || item.value)?.toString().toLowerCase().includes(searchText.toLowerCase()))
                ?.map(item => ({
                    ...item,
                    showText: (item.dropdownLabel || item.value)?.toString().replace(new RegExp(`(${searchText})`, 'gi'), match => `<span class="highlight">${match}</span>`)
                }));
            setTempValue(searchText);
            setFilteredList(tmpfilteredList);
        } else {
            setFilteredList([...filteredList]);
        }
    }, [propOptions, sort, filteredList, tempValue]);

    const isSelected = useCallback((item: DropdownOption, selectedValue: DropdownOption | DropdownOption[] | null, mode: 'single' | 'multiple') => {
        if (mode === 'multiple' && _.isArray(selectedValue)) {
            return selectedValue?.some(i => i.value === item.value);
        }
        return !_.isArray(selectedValue) && selectedValue?.value === item.value;
    }, []);

    const getDisplayText = useCallback((item: DropdownOption, isItemSelected: boolean) => {
        if (item.showText) {
            return { __html: item.showText };
        }
        const displayText = (item.dropdownLabel || item.value || '').toString();
        return isItemSelected ? { __html: `<span class="highlight">${displayText}</span>` } : { __html: `${displayText}` };
    }, []);

    const itemTemplate = useCallback((item: DropdownOption) => {
        const isItemSelected = isSelected(item, selectedValue, mode);
        const displayText = getDisplayText(item, isItemSelected);

        return (
            <StyledMenuItemTemplate className={isItemSelected ? 'dropdownSelected' : ''}>
                {item.icon && (
                    <StyledMenuItemTemplateIcon className={isItemSelected ? 'iconSelected' : ''}>
                        {Icons(item.icon, isItemSelected ? HPHSkyBlue : undefined)}
                    </StyledMenuItemTemplateIcon>
                )}
                <StyledMenuItemTemplateValue dangerouslySetInnerHTML={displayText} />
                {item.isMaster && (
                    <StyledLozengesContainer id="LozengesContainer">
                        <Lozenges label={item.lozengesLabel} variation={item.lozengesVariation} />
                    </StyledLozengesContainer>
                )}
            </StyledMenuItemTemplate>
        );
    }, [isSelected, selectedValue, mode, getDisplayText]);

    const selectedMultipleItemTemplate = useCallback((item: DropdownOption) => (
        <StyledMenuItemTemplate>
            {item.icon && <StyledMenuItemTemplateIcon className="icon">{Icons(item.icon)}</StyledMenuItemTemplateIcon>}
            <StyledMenuItemTemplateValue>{item[field || 'value'] || item.value}</StyledMenuItemTemplateValue>
        </StyledMenuItemTemplate>
    ), [field]);

    const isNumber = useCallback((evt: React.KeyboardEvent<HTMLInputElement>) => evt.which > 31 && (evt.which < 48 || evt.which > 57) ? false : true, []);

    const isAlfa = useCallback((evt: React.KeyboardEvent<HTMLInputElement>) => evt.which > 31 && (evt.which < 65 || evt.which > 90) && (evt.which < 97 || evt.which > 122) ? false : true, []);

    const handleFreeText = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (freeTextInput && e.which === 13 && tempValue) {
            const itemExists = propOptions.find(item => item.value === tempValue || item.dropdownLabel === tempValue);
            if (!itemExists) {
                setFilteredList(prevFilteredList => [...prevFilteredList, { value: tempValue }]);
                const newValue = mode === 'multiple' ? [...(Array.isArray(selectedValue) ? selectedValue : []), { value: tempValue }] : tempValue;
                setSelectedValue(newValue as DropdownOption | DropdownOption[] | null);
                setTempValue('');
                if (mode === 'multiple') {
                    elemRef.current.inputRef.current.value = '';
                }
                onChange && onChange(newValue as DropdownOption | DropdownOption[] | null);
            }
        }
    }, [freeTextInput, tempValue, propOptions, mode, selectedValue, onChange]);

    const handleKeyPress = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        const shouldType = inputType === 'number' ? isNumber(e) : inputType === 'text' ? isAlfa(e) : true;
        shouldType ? handleFreeText(e) : e.preventDefault();
    }, [inputType, isNumber, isAlfa, handleFreeText]);

    // const handleBlur = useCallback(() => {
    //     const value = elemRef.current.inputRef.current.value;
    //     if (selectedValue && mode === 'single') {
    //         if (selectedValue === null || typeof selectedValue !== 'object') {
    //             setFilteredList(filteredList.length ? [filteredList[0]] : []);
    //             onChange && onChange(filteredList[0]);
    //         }
    //     } else if (value && mode === 'multiple') {
    //         let selected = selectedValue!;
    //         let selectedOptions = Array.isArray(selected) ? selected?.map(i => i?.value) : [];
    //         let firstItem = filteredList[0];
    //         const newValue = !selectedOptions.includes(firstItem?.value) && filteredList.length ? [...(Array.isArray(selectedValue) ? selectedValue : []), filteredList[0]] : [...(Array.isArray(selectedValue) ? selectedValue : [])];
    //         setSelectedValue(newValue);
    //         if (mode === 'multiple') {
    //             elemRef.current.inputRef.current.value = '';
    //         }
    //         !selectedOptions.includes(firstItem?.value) && onChange && onChange(newValue);
    //     }
    // }, [selectedValue, mode, filteredList, onChange]);

    const onDropdownClickInternal = useCallback(() => {
        const pAuto = elemRef.current.container;
        const rect = pAuto.getBoundingClientRect();
        setPanelLeft(rect.left);
        onDropdownClick && onDropdownClick();
        if (elemRef.current && disableInput) {
            elemRef.current.inputRef.current.blur();
        }
        setTempValue('');
    }, [onDropdownClick, disableInput]);

    useEffect(() => {
        if (JSON.stringify(propOptions) === JSON.stringify(options) && selectedValue === propsValue) return;

        let currentSelectedValue: any = propsValue;
        let sortedOptions = propOptions.sort((a, b) => a?.dropdownLabel!.localeCompare(b?.dropdownLabel!));
        if (typeof propsValue === 'string' && propsValue.trim()) {
            const filteredList = sortedOptions?.filter(item => (item.value || item.dropdownLabel)?.toString().toLowerCase() === propsValue.toLowerCase());
            currentSelectedValue = filteredList[0] || currentSelectedValue;
        } else if (Array.isArray(propsValue)) {
            currentSelectedValue = propsValue;
        } else {
            currentSelectedValue = propsValue && typeof propsValue === 'object' ? propsValue : currentSelectedValue;
        }
        setSelectedValue(currentSelectedValue);
    }, [onChange, options, propOptions, propsValue, selectedValue, setSelectedValue]);

    useEffect(() => {
        if (JSON.stringify(propOptions) !== JSON.stringify(options)) {
            setPropOptions(options);
        }
    }, [options, propOptions])

    const handleOnChange = useCallback((e: AutoCompleteChangeParams) => {
        if (e.value && _.isArray(e.value) && !_.isEmpty(e.value) && e.value.length > selectedValue?.length) {
            const value = e.value[e.value.length - 1].value;

            if (_.some(selectedValue, (item: DropdownOption) => _.isEqual(item.value, value))) {
                return;
            }
        }

        setSelectedValue(e.value);
        setTempValue('');
        onChange && onChange(e.value);
    }, [onChange, selectedValue]);

    const handleOnHide = useCallback(() => {
        if (filteredList.length && typeof selectedValue === 'string' && tempValue && selectedValue === tempValue) {
            setSelectedValue(filteredList[0]);
            setTempValue('');
            onChange && onChange(filteredList[0]);
        } else if (!filteredList.length && typeof selectedValue === 'string' && tempValue) {
            setSelectedValue(null);
            setTempValue('');
        }
    }, [filteredList, selectedValue, tempValue, onChange, setSelectedValue, setTempValue]);

    let inputWidth, panelWidth, autoCompleteWidth = width;
    if (width && !isNaN(Number(width.replace('px', '').replace('%', '')))) {
        const w = Number(width.replace('px', '').replace('%', ''));
        panelWidth = `${w - 2}px`;
        // autoCompleteWidth = `${w - 45}px`;
        inputWidth = Array.isArray(selectedValue) && selectedValue.length ? (w * .8) : '100%';
    }

    return <>
        <AutoCompleteGlobalStyles panelLeft={panelLeft} />
        <StyledInputWrapper onMouseLeave={() => document.body.click()} className={`${(!freeTextInput && disableInput && mode === 'multiple') ? 'inputDisable' : ''}`}>
            <StyledInputLabel ref={inputLabel}>
                {label} {required && !optional && <StyledRequired>*</StyledRequired>}
                {optional && !required && <StyledOptional><Lozenges variation={'Optional Label'} /></StyledOptional>}
                {helpIcon && <IconButton fileName={'Icon-help'} size={'small'} idIcon={`Icon-help-${id}`} toolTipPlacement={'right'} toolTipArrow={false} disabled={false} toolTipText={toolTipText} {...rest} />}
            </StyledInputLabel>
            <StyledAutoComplete
                {...rest}
                arrowBackground={arrowBackground}
                autoCompleteWidth={autoCompleteWidth}
                ref={elemRef}
                autoHighlight={false}
                appendTo="self"
                errorMessage={!!errorMessage}
                {...freeTextInput ? { onKeyPress: handleKeyPress, dropdownIcon: 'Icon-auto-text' } : {}}
                onKeyPress={handleKeyPress}
                value={selectedValue}
                suggestions={filteredList}
                completeMethod={searchList}
                field={field || 'value'}
                onDropdownClick={onDropdownClickInternal}
                dropdown
                {...mode === 'multiple' ?
                    { multiple: true, selectedItemTemplate: selectedMultipleItemTemplate, removeIcon: 'pi pi-times' } :
                    {}}
                {...isVirtualScroll ? {
                    virtualScrollerOptions: {
                        ...virtualScrollerOptions,
                        ...!virtualScrollerOptions?.itemSize ? { itemSize: 30 } : {}, style: { width: panelWidth },
                    }
                } : {}}
                scrollHeight={scrollHeight === '' || scrollHeight === undefined ? (propOptions.length < 7 ? `${options.length * 30}px ` : '225px') : scrollHeight}
                style={{ width }}
                placeholder={placeholder}
                itemTemplate={itemTemplate}
                inputStyle={{ width: inputWidth }}
                readOnly={!freeTextInput && disableInput}
                onChange={handleOnChange}
                onHide={handleOnHide}
            />
            <StyledInputErrorMsg>{errorMessage}</StyledInputErrorMsg>
        </StyledInputWrapper>
    </>

};

export default NbisInputDropDown;

